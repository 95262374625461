
import { Component, Prop, Vue } from "vue-property-decorator";
import { toNumber } from "@/tools";

@Component({
  data: () => {
    return {
      toNumber,
    };
  },
})
export default class RoughnessCriteriaUpsert extends Vue {
  @Prop({ required: true })
  maximum!: boolean | null;

  @Prop({ required: true })
  isEditing!: boolean;
}
