
import { Component, Vue } from "vue-property-decorator";

import ProceadureGlobalView from "@/components/Core/Proceadures/ProceadureGlobalView.vue";

@Component({
  components: {
    ProceadureGlobalView,
  },
})
export default class Proceadures extends Vue {}
