
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";
import { toNullableNumber } from "@/tools";

@Component({
  data: () => {
    return {
      toNullableNumber,
    };
  },
})
export default class ThicknessCriteriaUpsert extends Vue {
  @Prop({ required: true })
  minimum!: number | null;

  @Prop({ required: true })
  maximum!: number | null;

  @Prop({ required: true })
  isEditing!: boolean;
}
