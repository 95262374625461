
import { Component, Inject, InjectReactive, Vue } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S, SERVER_CONFIG } from "@/config/literals";

import { ServerConfig } from "prometheus-synced-ui";
import { ProceadureDetails, ProceadureInfos, LITE_FEATURE } from "@/domain";

import { Feature } from "vue-feature-flags";
import Loading from "@/views/Loading.vue";
import ProceaduresList from "@/components/Core/Proceadures/ProceadureList.vue";
import ProceadurePreview from "@/components/Core/Proceadures/ProceadurePreview.vue";
import ProceadureCreate from "@/components/Core/Create/Proceadures/ProceadureCreate.vue";

import { IProceadureService } from "@/services";

@Component({
  components: {
    ProceaduresList,
    ProceadurePreview,
    ProceadureCreate,
    Loading,
    Feature
  },
  data: () => {
    return {
      LITE_FEATURE,
    };
  },
})
export default class ProceadureGlobalView extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  get proceadureService() {
    return this.container.resolve<IProceadureService>(S.PROCEADURE_SERVICE);
  }

  get existingNames(): string[] {
    return _.chain(this.proceadures)
      .filter((p) => p.id != this.selected)
      .map((s) => s.name)
      .value();
  }

  loading: boolean = false;
  showPreview: boolean = false;
  selected: string | null = null;
  proceadures: ProceadureInfos[] = [];

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.proceadures = await this.proceadureService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  updateSelected(value: string) {
    this.selected = value;
    this.showPreview = this.selected != null;
  }

  addProceadure() {
    this.selected = null;
    this.showPreview = true;
  }

  create(proceadureToAdd: ProceadureDetails) {
    this.proceadures.push(ProceadureInfos.fromDetails(proceadureToAdd));
    this.showPreview = false;
    this.selected = null;
  }

  update(proceadureToUpdate: ProceadureDetails) {
    const index = _.findIndex(
      this.proceadures,
      (p) => p.id == proceadureToUpdate.id
    );
    if (index != -1) {
      this.proceadures.splice(
        index,
        1,
        ProceadureInfos.fromDetails(proceadureToUpdate)
      );
    }
  }
}
