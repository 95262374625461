
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import _ from "lodash";

import UncertaintyStepUpsert from "@/components/Core/Update/Proceadures/Steps/Uncertainty/UncertaintyStepUpsert.vue";

import { UpsertUncertaintyStepDTO } from "@/services";

@Component({
  components: {
    UncertaintyStepUpsert,
  },
})
export default class UncertaintyUpdate extends Vue {
  @Prop({ required: true })
  value!: UpsertUncertaintyStepDTO[];

  @Prop({ required: true })
  isEditing!: boolean;

  get items() {
    return [
      {
        text: "Valeur fixe",
        value: true,
      },
      {
        text: "Tableau",
        value: false,
      },
    ];
  }

  get currentValue(): UpsertUncertaintyStepDTO[] {
    return _.chain(this.steps)
      .map((s) => {
        return {
          lowLimit: (s.lowLimit && s.lowLimit) || 0,
          highLimit: s.highLimit,
          uncertaintyValue: (s.uncertaintyValue && s.uncertaintyValue) || 0,
        };
      })
      .value();
  }

  isConstant: boolean = false;
  steps: {
    lowLimit: number | null;
    highLimit: number | null;
    uncertaintyValue: number | null;
  }[] = [];

  mounted() {
    if (this.value) {
      this.steps = _.map(this.value, (s) => {
        return {
          lowLimit: s.lowLimit,
          highLimit: s.highLimit,
          uncertaintyValue: s.uncertaintyValue,
        };
      });
    }
    this.reset();
  }

  getPrevious(index: number): {
    lowLimit: number | null;
    highLimit: number | null;
    uncertaintyValue: number | null;
  } | null {
    if (index - 1 >= 0) {
      return this.steps[index - 1];
    } else return null;
  }

  getNext(index: number): {
    lowLimit: number | null;
    highLimit: number | null;
    uncertaintyValue: number | null;
  } | null {
    if (index + 1 < this.steps.length) {
      return this.steps[index + 1];
    } else return null;
  }

  updateLowLimit(index: number, value: number | null) {
    this.updateSteps(index, {
      lowLimit: value,
      highLimit: this.steps[index].highLimit,
      uncertaintyValue: this.steps[index].uncertaintyValue,
    });
  }

  updateHighLimit(index: number, value: number | null) {
    this.updateSteps(index, {
      lowLimit: this.steps[index].lowLimit,
      highLimit: value,
      uncertaintyValue: this.steps[index].uncertaintyValue,
    });
  }

  updateUncertaintyValue(index: number, value: number | null) {
    this.updateSteps(index, {
      lowLimit: this.steps[index].lowLimit,
      highLimit: this.steps[index].highLimit,
      uncertaintyValue: value,
    });
  }

  addStep() {
    const lowLimit: number | null =
      (this.steps.length > 0 && this.steps[this.steps.length - 1].highLimit) ||
      null;

    this.steps.push({
      lowLimit: lowLimit,
      highLimit: null,
      uncertaintyValue: null,
    });

    this.$emit("input", this.currentValue);
  }

  updateStep(
    index: number,
    step: {
      lowLimit: number | null;
      highLimit: number | null;
      uncertaintyValue: number | null;
    }
  ) {
    this.steps.splice(index, 1, {
      uncertaintyValue: step.uncertaintyValue,
      lowLimit: step.lowLimit,
      highLimit: step.highLimit,
    });
    this.$emit("input", this.currentValue);
  }

  updateSteps(
    index: number,
    step: {
      lowLimit: number | null;
      highLimit: number | null;
      uncertaintyValue: number | null;
    }
  ) {
    const newSteps = _.cloneDeep(this.steps);

    const previousStep = (index > 0 && newSteps[index - 1]) || null;
    if (previousStep) {
      this.updateStep(index - 1, {
        uncertaintyValue: previousStep.uncertaintyValue,
        lowLimit: previousStep.lowLimit,
        highLimit: step.lowLimit,
      });
    }

    const nextStep =
      (index < newSteps.length - 1 && newSteps[index + 1]) || null;
    if (nextStep && step.highLimit != null) {
      this.updateStep(index + 1, {
        uncertaintyValue: nextStep.uncertaintyValue,
        lowLimit: step.highLimit,
        highLimit: nextStep.highLimit,
      });
    }

    this.updateStep(index, step);
  }

  removeStep(index: number) {
    const lowLimit = this.steps[index].lowLimit;
    this.steps.splice(index, 1);

    if (index < this.steps.length) {
      const nextStep = this.steps[index];
      this.updateSteps(index, {
        uncertaintyValue: nextStep.uncertaintyValue,
        lowLimit: lowLimit,
        highLimit: nextStep.highLimit,
      });
    }
    this.$emit("input", this.currentValue);
  }

  onTypeChange(value: boolean) {
    this.isConstant = value;
    if (this.isConstant) {
      this.steps = [];
      this.$emit("input", []);
    } else {
      this.addStep();
    }
  }

  reset() {
    this.isConstant = this.steps.length == 0;
  }

  @Watch("steps")
  onStepChange = this.reset;
}
