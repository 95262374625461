var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-subheader',{staticClass:"font-weight-bold"},[_vm._v("Critère étalon")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('material-shade-autocomplete',{attrs:{"value":_vm.materialShadeId,"shade-id-to-include":_vm.updateMode && _vm.materialShadeId != null && _vm.materialShadeId},on:{"input":function($event){return _vm.$emit('update:material-shade-id', $event)}}})],1),_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.celerityValue,"type":"number","suffix":"m/s","label":"Célérité","rules":[
          (v) => v != null || 'Ce champ est requis',
          (v) => v > 0 || 'Ce champ doit être strictement positif',
          _vm.celerityValue == null ||
            _vm.celerityRange == null ||
            _vm.celerityValue >= _vm.celerityRange ||
            'La célérité doit être supérieure à la plage',
        ]},on:{"input":function($event){_vm.$emit('update:celerity-value', _vm.toNumber($event))}}})],1),_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.celerityRange,"type":"number","prefix":"±","suffix":"m/s","label":"Plage de célérité","rules":[
          (v) => v != null || 'Ce champ est requis',
          (v) => v > 0 || 'Ce champ doit être strictement positif',
          _vm.celerityValue == null ||
            _vm.celerityRange == null ||
            _vm.celerityValue >= _vm.celerityRange ||
            'La plage doit être inférieure à la célérité',
        ]},on:{"input":function($event){_vm.$emit('update:celerity-range', _vm.toNumber($event))}}})],1),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"icon":"","color":"red darken-4","disabled":!_vm.isEditing},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }