
import { Component, Prop, Vue } from "vue-property-decorator";

import { toNumber } from "@/tools";

import { UpsertThresholdStepDTO } from "@/services";

@Component({
  data: () => {
    return {
      toNumber,
    };
  },
})
export default class ThresholdStepForm extends Vue {
  @Prop({ required: true })
  lowLimit!: number | null;

  @Prop({ required: true })
  highLimit!: number | null;

  @Prop({ required: true })
  thresholdValue!: number | null;

  @Prop({ required: true })
  isEditing!: boolean;

  @Prop({ required: true })
  previousStep!: UpsertThresholdStepDTO | null;

  @Prop({ required: true })
  nextStep!: UpsertThresholdStepDTO | null;

  @Prop({ required: true })
  isLowLimitAllowed!: boolean;

  get hasSameNeighbour(): boolean {
    return (
      (this.previousStep != null &&
        this.previousStep.thresholdValue == this.thresholdValue) ||
      (this.nextStep != null &&
        this.nextStep.thresholdValue == this.thresholdValue)
    );
  }
}
