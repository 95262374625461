
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import ThresholdStepForm from "@/components/Core/Update/Proceadures/Steps/Threshold/ThresholdStepForm.vue";
import ThresholdCriteriaFields from "@/components/Core/Update/Proceadures/Steps/Threshold/ThresholdCriteriaFields.vue";

import { UpsertThresholdStepDTO } from "@/services";

@Component({
  components: {
    ThresholdStepForm,
    ThresholdCriteriaFields,
  },
})
export default class ThresholdUpdate extends Vue {
  @Prop({ required: true })
  steps!: UpsertThresholdStepDTO[];

  @Prop({ required: true })
  isEditing!: boolean;

  getPrevious(index: number): UpsertThresholdStepDTO | null {
    if (index - 1 >= 0) {
      return this.steps[index - 1];
    } else return null;
  }

  getNext(index: number): UpsertThresholdStepDTO | null {
    if (index + 1 < this.steps.length) {
      return this.steps[index + 1];
    } else return null;
  }

  updateLowLimit(index: number, value: number) {
    this.updateSteps(index, {
      lowLimit: value,
      highLimit: this.steps[index].highLimit,
      thresholdValue: this.steps[index].thresholdValue,
    });
  }

  updateHighLimit(index: number, value: number) {
    this.updateSteps(index, {
      lowLimit: this.steps[index].lowLimit,
      highLimit: value,
      thresholdValue: this.steps[index].thresholdValue,
    });
  }

  updateThresholdValue(index: number, value: number) {
    this.updateSteps(index, {
      lowLimit: this.steps[index].lowLimit,
      highLimit: this.steps[index].highLimit,
      thresholdValue: value,
    });
  }

  updateSteps(index: number, step: UpsertThresholdStepDTO) {
    const steps = _.cloneDeep(this.steps);
    const previousStep = (index > 0 && steps[index - 1]) || null;
    if (previousStep) {
      steps.splice(index - 1, 1, {
        thresholdValue: previousStep.thresholdValue,
        lowLimit: previousStep.lowLimit,
        highLimit: step.lowLimit,
      });
    }

    const nextStep = (index < steps.length - 1 && steps[index + 1]) || null;
    if (nextStep && step.highLimit != null) {
      steps.splice(index + 1, 1, {
        thresholdValue: nextStep.thresholdValue,
        lowLimit: step.highLimit,
        highLimit: nextStep.highLimit,
      });
    }

    steps.splice(index, 1, step);
    this.$emit("update:steps", steps);
  }

  removeStep(index: number) {
    const steps = _.cloneDeep(this.steps);
    steps.splice(index, 1);
    if (index < steps.length) {
      const nextStep = steps[index];
      const lowLimit = this.steps[index].lowLimit;

      steps.splice(index, 1, {
        thresholdValue: nextStep.thresholdValue,
        lowLimit: lowLimit,
        highLimit: nextStep.highLimit,
      });
    }

    this.$emit("update:steps", steps);
  }
}
