
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { Component, Vue, Inject, Prop, Ref } from "vue-property-decorator";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import CriteriasExpansion from "@/components/Core/Proceadures/Criterias/CriteriasExpansion.vue";
import StepsExpansion from "@/components/Core/Proceadures/Steps/StepsExpansion.vue";
import ProceadureDocs from "@/components/Core/Proceadures/Docs/ProceadureDocs.vue";

import {
  IProceadureService,
  UpsertCalibrationCriteriaDTO,
  UpsertRoughnessCriteriaDTO,
  UpsertTemperatureCriteriaDTO,
  UpsertThicknessCriteriaDTO,
  UpsertThresholdCriteriaDTO,
  UpsertUncertaintyStepDTO,
  UpsertWeldingCriteriaDTO,
} from "@/services";

@Component({
  components: {
    CriteriasExpansion,
    StepsExpansion,
    ProceadureDocs,
  },
})
export default class ProceadureCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Ref("form")
  form!: any;

  @Prop({ required: true })
  forbiddenNames!: string[];

  get proceadureService() {
    return this.container.resolve<IProceadureService>(S.PROCEADURE_SERVICE);
  }

  get criteriaResume(): string {
    let activeCriterias: string[] = [];
    if (this.temperatureCriteria != null) {
      activeCriterias.push("Température");
    }
    if (this.thicknessCriteria != null) {
      activeCriterias.push("Épaisseur");
    }
    if (this.roughnessCriteria != null) {
      activeCriterias.push("Rugosité");
    }
    if (this.weldingCriteria != null) {
      activeCriterias.push("Bord de soudure");
    }
    if (this.calibrationCriteria != null) {
      activeCriterias.push("Étalon");
    }
    return _.join(activeCriterias, ", ");
  }

  loading: boolean = false;
  valid: boolean = true;
  name: string | null = null;
  files: File[] = [];
  temperatureCriteria: UpsertTemperatureCriteriaDTO | null = null;
  thicknessCriteria: UpsertThicknessCriteriaDTO | null = null;
  roughnessCriteria: UpsertRoughnessCriteriaDTO | null = null;
  weldingCriteria: UpsertWeldingCriteriaDTO | null = null;
  calibrationCriteria: UpsertCalibrationCriteriaDTO | null = null;
  uncertaintySteps: UpsertUncertaintyStepDTO[] = [];
  thresholdCriteria: UpsertThresholdCriteriaDTO | null = null;

  isAllowed(name: string): boolean {
    return (
      _.findIndex(
        this.forbiddenNames,
        (s) => s.toUpperCase() == name.toUpperCase()
      ) != -1
    );
  }

  async create() {
    if (this.form.validate()) {
      try {
        this.loading = true;
        const createdProcedure = await this.proceadureService.create({
          name: this.name!,
          files: this.files,
          temperatureCriteria: this.temperatureCriteria,
          thicknessCriteria: this.thicknessCriteria,
          roughnessCriteria: this.roughnessCriteria,
          weldingCriteria: this.weldingCriteria,
          calibrationCriteria: this.calibrationCriteria,
          uncertaintySteps: this.uncertaintySteps,
          thresholdCriteria: this.thresholdCriteria,
        });
        this.$emit("create", createdProcedure);
      } finally {
        this.loading = false;
      }
    }
  }
}
