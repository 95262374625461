
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import UncertaintyUpdate from "@/components/Core/Update/Proceadures/Steps/Uncertainty/UncertaintyUpdate.vue";
import ThresholdUpdate from "@/components/Core/Update/Proceadures/Steps/Threshold/ThresholdUpdate.vue";

import {
  UpsertThresholdCriteriaDTO,
  UpsertThresholdStepDTO,
  UpsertUncertaintyStepDTO,
} from "@/services";

@Component({
  components: {
    UncertaintyUpdate,
    ThresholdUpdate,
  },
})
export default class StepsExpansion extends Vue {
  @Prop({ required: true })
  isEditing!: boolean;

  @Prop({ required: true })
  isDisabled!: boolean;

  @Prop({ required: true })
  uncertaintySteps!: UpsertUncertaintyStepDTO[];

  @Prop({ required: true })
  thresholdCriteria!: UpsertThresholdCriteriaDTO | null;

  get label(): string {
    const messages: string[] = [];
    if (this.uncertaintySteps.length > 0) {
      messages.push(
        `${this.uncertaintySteps.length} échelon${
          this.uncertaintySteps.length > 1 ? "s" : ""
        } d'incertitude`
      );
    } else {
      messages.push("Incertitude fixe");
    }

    if (this.thresholdCriteria) {
      if (this.thresholdCriteria.steps.length > 0) {
        messages.push(
          `${this.thresholdCriteria.steps.length} échelon${
            this.thresholdCriteria.steps.length > 1 ? "s" : ""
          } de seuil de variation`
        );
      } else {
        messages.push("Seuil de variation fixe");
      }
    }
    return _.join(messages, ", ");
  }

  useThresholdCriteria: boolean = false;

  mounted() {
    if (this.thresholdCriteria) {
      this.useThresholdCriteria = true;
    }
  }

  addThresholdCriteria() {
    this.$emit("update:threshold-criteria", {
      onLastMeasures: true,
      onFirstMeasures: true,
      steps: [],
    });
    this.useThresholdCriteria = true;
  }

  updateOnFirstMeasures(value: boolean) {
    this.$emit("update:threshold-criteria", {
      onFirstMeasures: value,
      onLastMeasures: this.thresholdCriteria!.onLastMeasures,
      steps: this.thresholdCriteria!.steps,
    });
  }

  updateOnLastMeasures(value: boolean) {
    this.$emit("update:threshold-criteria", {
      onFirstMeasures: this.thresholdCriteria!.onFirstMeasures,
      onLastMeasures: value,
      steps: this.thresholdCriteria!.steps,
    });
  }

  updateSteps(value: UpsertThresholdStepDTO[]) {
    this.$emit("update:threshold-criteria", {
      onFirstMeasures: this.thresholdCriteria!.onFirstMeasures,
      onLastMeasures: this.thresholdCriteria!.onLastMeasures,
      steps: value,
    });
  }

  remove() {
    this.$emit("update:threshold-criteria", null);
    this.useThresholdCriteria = false;
  }
}
