
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import _ from "lodash";
import { toNumber } from "@/tools";

@Component({
  data: () => {
    return {
      toNumber,
    };
  },
})
export default class ConstantDeltaUpdate extends Vue {
  @Prop({ required: true })
  value!: number | null;

  @Prop({ required: true })
  isEditing!: boolean;

  @Prop({ required: true })
  deltas!: number[];

  @Prop({ required: true })
  index!: number;

  get isAllowed(): boolean {
    const forbiddens = _.cloneDeep(this.deltas);
    forbiddens.splice(this.index, 1);
    return _.findIndex(forbiddens, (s) => s == this.value) != -1;
  }

  existingRule: boolean | string = true;

  triggerValidation() {
    if (this.value != null) {
      this.existingRule = this.isAllowed
        ? "Cette valeur d'écart est déjà existante"
        : true;
    }
  }

  @Watch("deltas")
  onPropChange = this.triggerValidation;
}
