
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProceadureActions extends Vue {
  @Prop({ required: true })
  isEditing!: boolean;

  @Prop({ required: true })
  isDisabled!: boolean;

  @Prop({ required: true })
  loading!: boolean;

  @Prop({ required: true })
  valid!: boolean;
}
