
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import WeldingCriteriaUpsert from "@/components/Core/Update/Proceadures/Criterias/Child/WeldingCriteriaUpsert.vue";

import { UpsertWeldingCriteriaDTO } from "@/services";

@Component({
  components: {
    WeldingCriteriaUpsert,
  },
})
export default class WeldingCriteriaSelection extends Vue {
  @Prop({ required: true })
  value!: UpsertWeldingCriteriaDTO | null;

  @Prop({ required: true })
  isEditing!: boolean;

  get currentValue(): UpsertWeldingCriteriaDTO | null {
    if (this.isSelected) {
      const constants: number[] = _.map(this.constantDeltas, (d) =>
        d == null ? 0 : d
      );
      return {
        constantDeltas: (constants.length > 0 && constants) || [],
        deltaPercentage: (this.deltaPercentage && this.deltaPercentage) || 0,
      };
    } else {
      return null;
    }
  }

  deltaPercentage: number | null = null;
  constantDeltas: (number | null)[] = [];
  isSelected: boolean = false;

  mounted() {
    if (this.value) {
      this.deltaPercentage = this.value.deltaPercentage;
      this.constantDeltas = this.value.constantDeltas;
      this.isSelected = true;
    }
  }

  updateDeltas(val: (number | null)[]) {
    this.constantDeltas = val;
    this.$emit("input", this.currentValue);
  }

  updatePercentage(val: number | null) {
    this.deltaPercentage = val;
    this.$emit("input", this.currentValue);
  }

  reset() {
    this.constantDeltas = [];
    this.deltaPercentage = null;
    this.isSelected = false;
    this.$emit("input", this.currentValue);
  }
}
