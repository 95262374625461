
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import TemperatureCriteriaUpsert from "@/components/Core/Update/Proceadures/Criterias/Child/TemperatureCriteriaUpsert.vue";

import { UpsertTemperatureCriteriaDTO } from "@/services";

@Component({
  components: {
    TemperatureCriteriaUpsert,
  },
})
export default class TemperatureCriteriaSelection extends Vue {
  @Prop({ required: false })
  value!: UpsertTemperatureCriteriaDTO | null;

  @Prop({ required: true })
  isEditing!: boolean;

  get currentValue(): UpsertTemperatureCriteriaDTO | null {
    if (this.isSelected) {
      return {
        minimum: (this.minimum && this.minimum) || 0,
        maximum: (this.maximum && this.maximum) || 0,
        delta: this.delta,
      };
    } else {
      return null;
    }
  }

  minimum: number | null = null;
  maximum: number | null = null;
  delta: number | null = null;

  isSelected: boolean = false;

  mounted() {
    if (this.value) {
      this.minimum = this.value.minimum;
      this.maximum = this.value.maximum;
      this.delta = this.value.delta;
      this.isSelected = true;
    }
  }

  updateMin(val: number | null) {
    this.minimum = val;
    this.$emit("input", this.currentValue);
  }

  updateMax(val: number | null) {
    this.maximum = val;
    this.$emit("input", this.currentValue);
  }

  updateDelta(val: number | null) {
    this.delta = val;
    this.$emit("input", this.currentValue);
  }

  reset() {
    this.minimum = null;
    this.maximum = null;
    this.delta = null;
    this.isSelected = false;
    this.$emit("input", this.currentValue);
  }
}
