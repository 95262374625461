
import { Component, Prop, Vue } from "vue-property-decorator";

import CriteriasPreview from "@/components/Core/Proceadures/Criterias/CriteriasPreview.vue";
import {
  UpsertCalibrationCriteriaDTO,
  UpsertRoughnessCriteriaDTO,
  UpsertTemperatureCriteriaDTO,
  UpsertThicknessCriteriaDTO,
  UpsertWeldingCriteriaDTO,
} from "@/services";

@Component({
  components: {
    CriteriasPreview,
  },
})
export default class CriteriasExpansion extends Vue {
  @Prop({ required: true })
  temperatureCriteria!: UpsertTemperatureCriteriaDTO | null;

  @Prop({ required: true })
  thicknessCriteria!: UpsertThicknessCriteriaDTO | null;

  @Prop({ required: true })
  weldingCriteria!: UpsertWeldingCriteriaDTO | null;

  @Prop({ required: true })
  roughnessCriteria!: UpsertRoughnessCriteriaDTO | null;

  @Prop({ required: true })
  calibrationCriteria!: UpsertCalibrationCriteriaDTO | null;

  @Prop({ required: true })
  updateMode!: boolean;

  @Prop({ required: true })
  isEditing!: boolean;

  @Prop({ required: true })
  isDisabled!: boolean;

  @Prop({ required: true })
  label!: string;
}
