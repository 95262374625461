
import { Component, Prop, Vue } from "vue-property-decorator";
import { toNumber } from "@/tools";

import MaterialShadeAutocomplete from "@/components/General/Autocompletes/Equipments/MaterialShadeAutocomplete.vue";

@Component({
  components: {
    MaterialShadeAutocomplete,
  },
  data: () => {
    return {
      toNumber,
    };
  },
})
export default class CalibrationCriteriaUpsert extends Vue {
  @Prop({ required: true })
  materialShadeId!: string | null;

  @Prop({ required: true })
  celerityValue!: number | null;

  @Prop({ required: true })
  celerityRange!: number | null;

  @Prop({ required: true })
  updateMode!: boolean;

  @Prop({ required: true })
  isEditing!: boolean;
}
