
import { Component, Prop, Vue } from "vue-property-decorator";
import { toNullableNumber } from "@/tools";

@Component({
  data: () => {
    return {
      toNullableNumber,
    };
  },
})
export default class TemperatureCriteriaUpsert extends Vue {
  @Prop({ required: true })
  minimum!: number | null;

  @Prop({ required: true })
  maximum!: number | null;

  @Prop({ required: true })
  delta!: number | null;

  @Prop({ required: true })
  isEditing!: boolean;
}
