
import { Component, Prop, Vue } from "vue-property-decorator";
import { lightFormat } from "date-fns";
import _ from "lodash";

import { PdfFileInfos } from "@/domain";
import { UserInfos } from "prometheus-synced-ui";

@Component({
  filters: {
    pretty(date: Date): string {
      return lightFormat(date, "dd/MM/yyyy");
    },
  },
})
export default class PdfFile extends Vue {
  @Prop({ required: true })
  file!: PdfFileInfos;

  @Prop({ required: true })
  isEditing!: boolean;

  @Prop({ required: true })
  users!: UserInfos[];

  get creatorName(): string {
    return _.find(this.users, (u) => u.id == this.file.creatorId)!.fullName;
  }

  base64ToArrayBuffer(base64: any): Uint8Array {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  open() {
    const arrayBuffer = this.base64ToArrayBuffer(this.file.content);
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
}
