
import { Component, InjectReactive, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import { SERVER_CONFIG } from "@/config/literals";

import { ServerConfig } from "prometheus-synced-ui";
import { LITE_FEATURE, ProceadureInfos } from "@/domain";

import { Feature } from "vue-feature-flags";

@Component({
  components: {
    Feature,
  },
  data: () => {
    return {
      LITE_FEATURE,
    };
  },
})
export default class ProceaduresList extends Vue {
  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: true })
  proceadures!: ProceadureInfos[];

  get items() {
    return (
      (this.showDisabled &&
        _.map(this.proceadures, (p) => {
          return {
            id: p.id,
            text: p.name,
            disabled: p.disabled,
          };
        })) ||
      _.chain(this.proceadures)
        .filter((p) => !p.disabled)
        .map((p) => {
          return {
            id: p.id,
            text: p.name,
            disabled: p.disabled,
          };
        })
        .value()
    );
  }

  emit(value: any) {
    if (value && value != this.value) {
      this.$emit("input", value);
    }
  }

  showDisabled: boolean = false;
}
