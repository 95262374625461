
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { PdfFileInfos } from "@/domain";
import { UserInfos } from "prometheus-synced-ui";

import PdfFile from "@/components/Core/Proceadures/Docs/PdfFile.vue";
import Loading from "@/views/Loading.vue";

import { IUserService } from "@/services";

@Component({
  components: {
    PdfFile,
    Loading,
  },
})
export default class ProceadureDocs extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  files!: PdfFileInfos[];

  @Prop({ required: true })
  filesToAdd!: File[];

  @Prop({ required: true })
  updateMode!: boolean;

  @Prop({ required: true })
  isEditing!: boolean;

  get userService() {
    return this.container.resolve<IUserService>(S.USER_SERVICE);
  }

  users: UserInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.users = await this.userService.getMany({
        searchDate: null,
        includeSuperAdministrator: true,
        includeAdministrator: true,
        includeDbUser: true,
        includeExamReportUser: true,
        includeMeasureAppUser: true,
        includeNone: true,
        ids: [],
      });
    } finally {
      this.loading = false;
    }
  }

  remove(index: number) {
    const pdfs = _.cloneDeep(this.filesToAdd);
    pdfs.splice(index, 1);
    this.$emit("update:files-to-add", pdfs);
  }

  isAllowed(): boolean {
    if (this.filesToAdd.length > 0) {
      const valids = _.map(this.filesToAdd, (f) => {
        const strings = f!.name.toString().split(".");
        return strings[strings.length - 1].toLowerCase() == "pdf";
      });
      return _.findIndex(valids, (v) => !v) == -1;
    } else return true;
  }

  removeExisting(index: number) {
    const pdfs = _.cloneDeep(this.files);
    pdfs.splice(index, 1);
    this.$emit("update:files", pdfs);
  }
}
