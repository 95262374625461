var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('span',{staticClass:"font-weight-regular text-subtitle-2 text-center grey--text text--darken-2"},[_vm._v("Domaine d'application")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"input-value":_vm.onFirstMeasures,"label":"Sur mesures initiales","rules":[
          _vm.onFirstMeasures ||
            _vm.onLastMeasures ||
            `Le seuil doit s'appliquer à au moins un domaine`,
        ]},on:{"change":function($event){return _vm.$emit('update:on-first-measures', !_vm.onFirstMeasures)}}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"input-value":_vm.onLastMeasures,"label":"Sur mesures dernières","rules":[
          _vm.onFirstMeasures ||
            _vm.onLastMeasures ||
            `Le seuil doit s'appliquer à au moins un domaine`,
        ]},on:{"change":function($event){return _vm.$emit('update:on-last-measures', !_vm.onLastMeasures)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }