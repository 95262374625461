
import {
  Component,
  Inject,
  InjectReactive,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVER_CONFIG, SERVICES as S } from "@/config/literals";

import { ServerConfig } from "prometheus-synced-ui";
import { LITE_FEATURE, PdfFileInfos, ProceadureDetails } from "@/domain";

import { Feature } from "vue-feature-flags";
import Loading from "@/views/Loading.vue";
import CriteriasExpansion from "@/components/Core/Proceadures/Criterias/CriteriasExpansion.vue";
import StepsExpansion from "@/components/Core/Proceadures/Steps/StepsExpansion.vue";
import ProceadureActions from "@/components/Core/Proceadures/ProceadureActions.vue";
import ProceadureDocs from "@/components/Core/Proceadures/Docs/ProceadureDocs.vue";

import {
  IProceadureService,
  UpsertCalibrationCriteriaDTO,
  UpsertRoughnessCriteriaDTO,
  UpsertTemperatureCriteriaDTO,
  UpsertThicknessCriteriaDTO,
  UpsertThresholdCriteriaDTO,
  UpsertUncertaintyStepDTO,
  UpsertWeldingCriteriaDTO,
} from "@/services";

@Component({
  components: {
    ProceadureActions,
    CriteriasExpansion,
    StepsExpansion,
    ProceadureDocs,
    Loading,
    Feature,
  },
  data: () => {
    return {
      LITE_FEATURE,
    };
  },
})
export default class ProceadurePreview extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @InjectReactive(SERVER_CONFIG)
  serverConfig!: ServerConfig;

  @Ref("form")
  form!: any;

  @Prop({ required: true })
  proceadureId!: string;

  @Prop({ required: true })
  forbiddenNames!: string[];

  get proceadureService() {
    return this.container.resolve<IProceadureService>(S.PROCEADURE_SERVICE);
  }

  get criteriaResume(): string {
    const activeCriterias: string[] = [];
    if (this.temperatureCriteria != null) {
      activeCriterias.push("Température");
    }
    if (this.thicknessCriteria != null) {
      activeCriterias.push("Épaisseur");
    }
    if (this.roughnessCriteria != null) {
      activeCriterias.push("Rugosité");
    }
    if (this.weldingCriteria != null) {
      activeCriterias.push("Bord de soudure");
    }
    if (this.calibrationCriteria != null) {
      activeCriterias.push("Étalon");
    }
    return _.join(activeCriterias, ", ");
  }

  fetching: boolean = false;
  updating: boolean = false;
  proceadure: ProceadureDetails | null = null;
  valid: boolean = true;
  files: PdfFileInfos[] = [];
  filesToAdd: File[] = [];
  temperatureCriteria: UpsertTemperatureCriteriaDTO | null = null;
  thicknessCriteria: UpsertThicknessCriteriaDTO | null = null;
  roughnessCriteria: UpsertRoughnessCriteriaDTO | null = null;
  weldingCriteria: UpsertWeldingCriteriaDTO | null = null;
  calibrationCriteria: UpsertCalibrationCriteriaDTO | null = null;
  uncertaintySteps: UpsertUncertaintyStepDTO[] = [];
  thresholdCriteria: UpsertThresholdCriteriaDTO | null = null;

  name: string | null = null;
  isEditing: boolean = false;

  mounted() {
    this.reset();
  }

  async fetch() {
    try {
      this.fetching = true;
      const proceadure = await this.proceadureService.get(this.proceadureId);
      this.assign(proceadure);
    } finally {
      this.fetching = false;
    }
  }

  isAllowed(name: string) {
    return (
      _.findIndex(
        this.forbiddenNames,
        (s) => s.toUpperCase() == name.toUpperCase()
      ) != -1
    );
  }

  async update() {
    if (this.form.validate()) {
      try {
        this.updating = true;
        const filesToRemove = _.filter(
          this.proceadure!.pdfFiles,
          (f) => !_.find(this.files, (f2) => f2.id == f.id)
        );

        const updatedProceadure = await this.proceadureService.update(
          this.proceadure!.id,
          {
            name: this.name!,
            files: this.filesToAdd,
            fileIdsToDelete: _.map(filesToRemove, (f) => f.id),
            temperatureCriteria: this.temperatureCriteria,
            thicknessCriteria: this.thicknessCriteria,
            roughnessCriteria: this.roughnessCriteria,
            weldingCriteria: this.weldingCriteria,
            calibrationCriteria: this.calibrationCriteria,
            uncertaintySteps: this.uncertaintySteps,
            thresholdCriteria: this.thresholdCriteria,
            disabled: this.proceadure!.disabled,
          }
        );
        this.$emit("update", updatedProceadure);
        this.assign(updatedProceadure);
        this.isEditing = false;
      } finally {
        this.updating = false;
      }
    }
  }

  async updateDisabled() {
    try {
      this.updating = true;
      const updatedProceadure = await this.proceadureService.update(
        this.proceadure!.id,
        {
          name: this.proceadure!.name,
          files: [],
          fileIdsToDelete: [],
          temperatureCriteria: this.proceadure!.temperatureCriteria,
          thicknessCriteria: this.proceadure!.thicknessCriteria,
          roughnessCriteria: this.proceadure!.roughnessCriteria,
          weldingCriteria: this.proceadure!.weldingCriteria,
          calibrationCriteria:
            (this.proceadure!.calibrationCriteria && {
              celerityRange: this.proceadure!.calibrationCriteria.celerityRange,
              celerityValue: this.proceadure!.calibrationCriteria.celerityValue,
              materialShadeId:
                this.proceadure!.calibrationCriteria.materialShade.id,
            }) ||
            null,
          uncertaintySteps:
            (this.proceadure!.uncertaintyCriteria &&
              this.proceadure!.uncertaintyCriteria.steps) ||
            [],
          thresholdCriteria: this.proceadure!.thresholdCriteria,
          disabled: !this.proceadure!.disabled,
        }
      );

      this.$emit("update", updatedProceadure);
      this.assign(updatedProceadure);
      this.isEditing = false;
    } finally {
      this.updating = false;
    }
  }

  assign(proceadure: ProceadureDetails) {
    this.proceadure = proceadure;
    this.name = this.proceadure.name;
    this.files = this.proceadure.pdfFiles;
    this.filesToAdd = [];
    this.temperatureCriteria = this.proceadure.temperatureCriteria;
    this.thicknessCriteria = this.proceadure.thicknessCriteria;
    this.roughnessCriteria = this.proceadure.roughnessCriteria;
    this.weldingCriteria = this.proceadure.weldingCriteria;
    this.calibrationCriteria =
      (this.proceadure.calibrationCriteria && {
        celerityRange: this.proceadure.calibrationCriteria.celerityRange,
        celerityValue: this.proceadure.calibrationCriteria.celerityValue,
        materialShadeId: this.proceadure.calibrationCriteria.materialShade.id,
      }) ||
      null;
    this.uncertaintySteps =
      (this.proceadure.uncertaintyCriteria &&
        this.proceadure.uncertaintyCriteria.steps) ||
      [];
    this.thresholdCriteria = this.proceadure.thresholdCriteria;
  }

  reset() {
    this.isEditing = false;
    this.fetch();
  }

  @Watch("proceadureId")
  onPropChange = this.reset;
}
