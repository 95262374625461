
import { Component, Prop, Vue } from "vue-property-decorator";

import TemperatureCriteriaSelection from "@/components/Core/Update/Proceadures/Criterias/Selections/TemperatureCriteriaSelection.vue";
import ThicknessCriteriaSelection from "@/components/Core/Update/Proceadures/Criterias/Selections/ThicknessCriteriaSelection.vue";
import CalibrationCriteriaSelection from "@/components/Core/Update/Proceadures/Criterias/Selections/CalibrationCriteriaSelection.vue";
import RoughnessCriteriaSelection from "@/components/Core/Update/Proceadures/Criterias/Selections/RoughnessCriteriaSelection.vue";
import WeldingCriteriaSelection from "@/components/Core/Update/Proceadures/Criterias/Selections/WeldingCriteriaSelection.vue";

import {
  UpsertCalibrationCriteriaDTO,
  UpsertRoughnessCriteriaDTO,
  UpsertTemperatureCriteriaDTO,
  UpsertThicknessCriteriaDTO,
  UpsertWeldingCriteriaDTO,
} from "@/services";

@Component({
  components: {
    TemperatureCriteriaSelection,
    ThicknessCriteriaSelection,
    CalibrationCriteriaSelection,
    RoughnessCriteriaSelection,
    WeldingCriteriaSelection,
  },
})
export default class CriteriasPreview extends Vue {
  @Prop({ required: true })
  temperatureCriteria!: UpsertTemperatureCriteriaDTO | null;

  @Prop({ required: true })
  thicknessCriteria!: UpsertThicknessCriteriaDTO | null;

  @Prop({ required: true })
  roughnessCriteria!: UpsertRoughnessCriteriaDTO | null;

  @Prop({ required: true })
  weldingCriteria!: UpsertWeldingCriteriaDTO | null;

  @Prop({ required: true })
  calibrationCriteria!: UpsertCalibrationCriteriaDTO | null;

  @Prop({ required: true })
  updateMode!: boolean;

  @Prop({ required: true })
  isEditing!: boolean;
}
