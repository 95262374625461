
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import CalibrationCriteriaUpsert from "@/components/Core/Update/Proceadures/Criterias/Child/CalibrationCriteriaUpsert.vue";

import { UpsertCalibrationCriteriaDTO } from "@/services";

@Component({
  components: {
    CalibrationCriteriaUpsert,
  },
})
export default class CalibrationCriteriaSelection extends Vue {
  @Prop({ required: true })
  value!: UpsertCalibrationCriteriaDTO | null;

  @Prop({ required: true })
  updateMode!: boolean;

  @Prop({ required: true, default: () => -1 })
  isEditing!: boolean;

  get currentValue(): UpsertCalibrationCriteriaDTO | null {
    if (this.isSelected) {
      return {
        materialShadeId: (this.materialShadeId && this.materialShadeId) || "",
        celerityRange: (this.celerityRange && this.celerityRange) || 0,
        celerityValue: (this.celerityValue && this.celerityValue) || 0,
      };
    } else {
      return null;
    }
  }

  isSelected: boolean = false;
  materialShadeId: string | null = null;
  celerityValue: number | null = null;
  celerityRange: number | null = null;

  mounted() {
    if (this.value) {
      this.materialShadeId = this.value.materialShadeId;
      this.celerityRange = this.value.celerityRange;
      this.celerityValue = this.value.celerityValue;
      this.isSelected = true;
    }
  }

  updateShade(value: string | null) {
    this.materialShadeId = value;
    this.$emit("input", this.currentValue);
  }

  updateValue(value: number | null) {
    this.celerityValue = value;
    this.$emit("input", this.currentValue);
  }

  updateRange(value: number | null) {
    this.celerityRange = value;
    this.$emit("input", this.currentValue);
  }

  reset() {
    this.materialShadeId = null;
    this.celerityRange = null;
    this.celerityValue = null;
    this.isSelected = false;
    this.$emit("input", this.currentValue);
  }
}
