var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-subheader',{staticClass:"font-weight-bold"},[_vm._v("Critère d'épaisseur")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.minimum,"type":"number","suffix":"mm","label":"Épaisseur minimum","rules":[
          (v) => v != null || 'Ce champ est requis',
          _vm.minimum == null ||
            _vm.maximum == null ||
            _vm.minimum <= _vm.maximum ||
            'Le minimum doit être inférieur au maximum',
        ]},on:{"input":function($event){_vm.$emit('update:minimum', _vm.toNullableNumber($event))}}})],1),_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.maximum,"type":"number","suffix":"mm","label":"Épaisseur maximum","rules":[
          (v) => v != null || 'Ce champ est requis',
          _vm.minimum == null ||
            _vm.maximum == null ||
            _vm.minimum <= _vm.maximum ||
            'Le maximum doit être supérieur au minimum',
        ]},on:{"input":function($event){_vm.$emit('update:maximum', _vm.toNullableNumber($event))}}})],1),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"icon":"","color":"red darken-4","disabled":!_vm.isEditing},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }