
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IMaterialShadeService } from "@/services";

import { MaterialShadeInfos } from "@/domain";

@Component
export default class MaterialShadeAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  shadeIdToInclude!: string | null;

  get materialShadeService() {
    return this.container.resolve<IMaterialShadeService>(
      S.MATERIAL_SHADE_SERVICE
    );
  }

  get filteredShades() {
    const shades = _.filter(
      this.shades,
      (s) => !s.disabled || s.id == this.shadeIdToInclude
    );

    return _.map(shades, (s) => {
      return {
        id: s.id,
        name: s.shadeValue,
      };
    });
  }

  shades: MaterialShadeInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.shades = await this.materialShadeService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }
}
