
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import { toNumber } from "@/tools";

import ConstantDeltaUpdate from "@/components/Core/Update/Proceadures/Criterias/Child/ConstantDeltaUpdate.vue";

@Component({
  filters: {
    pretty(deltas: (number | null)[]): string {
      return _.join(deltas, " mm, ou ");
    },
  },
  components: {
    ConstantDeltaUpdate,
  },
  data: () => {
    return {
      toNumber,
    };
  },
})
export default class WeldingCriteriaUpsert extends Vue {
  @Prop({ required: true })
  deltaPercentage!: number | null;

  @Prop({ required: true })
  constantDeltas!: (number | null)[];

  @Prop({ required: true })
  isEditing!: boolean;

  addDelta() {
    const deltas = _.cloneDeep(this.constantDeltas);
    deltas.push(null);
    this.$emit("update:constant-deltas", deltas);
  }

  updateDelta(index: number, delta: number) {
    const deltas = _.cloneDeep(this.constantDeltas);
    deltas[index] = delta;
    this.$emit("update:constant-deltas", deltas);
  }

  removeDelta(index: number) {
    const deltas = _.cloneDeep(this.constantDeltas);
    deltas.splice(index, 1);
    this.$emit("update:constant-deltas", deltas);
  }
}
