var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"justify-center",attrs:{"align":"center"}},[_c('v-col',[_c('v-text-field',{staticClass:"py-0",attrs:{"value":_vm.lowLimit,"type":"number","disabled":!_vm.isLowLimitAllowed,"suffix":"mm","label":"Limite basse","rules":[
        (v) => v != null || 'Ce champ est requis',
        (v) => v >= 0 || 'Ce champ doit être positif',
        _vm.lowLimit == null ||
          _vm.highLimit == null ||
          _vm.highLimit > _vm.lowLimit ||
          'La limite basse doit être strictement inférieure à la limite haute',
      ]},on:{"input":function($event){_vm.$emit('update:low-limit', _vm.toNumber($event))}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-regular text-subtitle-1",domProps:{"textContent":_vm._s(`≤ ép. <`)}})]),_c('v-col',[_c('v-text-field',{staticClass:"py-0",attrs:{"value":_vm.highLimit,"type":"number","suffix":"mm","label":"Limite haute","rules":[
        (v) => v != null || 'Ce champ est requis',
        (v) => v >= 0 || 'Ce champ doit être positif',
        _vm.lowLimit == null ||
          _vm.highLimit == null ||
          _vm.highLimit > _vm.lowLimit ||
          'La limite haute doit être strictement supérieure à la limite basse',
      ]},on:{"input":function($event){_vm.$emit('update:high-limit', _vm.toNumber($event))}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" : ")]),_c('v-col',[_c('v-text-field',{staticClass:"py-0",attrs:{"value":_vm.thresholdValue,"type":"number","suffix":"mm","label":"Seuil","rules":[
        (v) => v != null || 'Ce champ est requis',
        (v) => v > 0 || 'Ce champ doit être strictement positif',
        !_vm.hasSameNeighbour ||
          `Deux échelons voisins doivent avoir une valeur de seuil différente`,
      ]},on:{"input":function($event){_vm.$emit('update:threshold-value', _vm.toNumber($event))}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":"red darken-4","disabled":!_vm.isEditing},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }