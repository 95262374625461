
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import ThresholdStepsForm from "@/components/Core/Update/Proceadures/Steps/Threshold/ThresholdStepsForm.vue";
import ThresholdCriteriaFields from "@/components/Core/Update/Proceadures/Steps/Threshold/ThresholdCriteriaFields.vue";

import { UpsertThresholdStepDTO } from "@/services";

@Component({
  components: {
    ThresholdStepsForm,
    ThresholdCriteriaFields,
  },
})
export default class ThresholdUpdate extends Vue {
  @Prop({ required: true })
  onLastMeasures!: boolean;

  @Prop({ required: true })
  onFirstMeasures!: boolean;

  @Prop({ required: true })
  steps!: UpsertThresholdStepDTO[];

  @Prop({ required: true })
  isEditing!: boolean;

  get items(): { text: string; value: boolean }[] {
    return [
      {
        text: "Valeur fixe",
        value: true,
      },
      {
        text: "Tableau",
        value: false,
      },
    ];
  }

  get isConstant(): boolean {
    return this.steps.length == 0;
  }

  onTypeChange(value: boolean) {
    if (value) {
      this.$emit("update:steps", []);
    } else {
      this.addStep();
    }
  }

  addStep() {
    const lowLimit: number =
      (this.steps.length > 0 && this.steps[this.steps.length - 1].highLimit) ||
      0;

    const steps = _.cloneDeep(this.steps);

    steps.push({
      lowLimit: lowLimit,
      highLimit: 0,
      thresholdValue: 0,
    });
    this.$emit("update:steps", steps);
  }

  remove() {
    this.$emit("remove");
  }
}
