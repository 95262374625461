
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { toNumber, toNullableNumber } from "@/tools";

import InfinityButton from "@/components/Core/Update/Proceadures/Steps/Uncertainty/InfinityButton.vue";

import { UpsertUncertaintyStepDTO } from "@/services";

@Component({
  data: () => {
    return {
      toNumber,
      toNullableNumber,
    };
  },
  components: {
    InfinityButton,
  },
})
export default class UncertaintyStepUpsert extends Vue {
  @Prop({ required: true })
  lowLimit!: number | null;

  @Prop({ required: true })
  highLimit!: number | null;

  @Prop({ required: true })
  uncertaintyValue!: number | null;

  @Prop({ required: true })
  isEditing!: boolean;

  @Prop({ required: true })
  previousStep!: UpsertUncertaintyStepDTO | null;

  @Prop({ required: true })
  nextStep!: UpsertUncertaintyStepDTO | null;

  @Prop({ required: true })
  isLowLimitAllowed!: boolean;

  @Prop({ required: true })
  isLast!: boolean;

  get isDisabled(): boolean {
    return this.isLast && this.isInfinity;
  }

  get hasSameNeighbour(): boolean {
    return (
      (this.previousStep != null &&
        this.previousStep.uncertaintyValue == this.uncertaintyValue) ||
      (this.nextStep != null &&
        this.nextStep.uncertaintyValue == this.uncertaintyValue)
    );
  }

  isInfinity: boolean = false;
  highLimitRule: boolean | string = true;

  mounted() {
    this.reset();
  }

  triggerValidation() {
    this.highLimitRule =
      this.isDisabled || this.highLimit != null || "Ce champ est requis";
  }

  updateHighLimit(value: number | null) {
    this.$emit("update:high-limit", value);
  }

  onInfinityChange() {
    this.isInfinity = !this.isInfinity;
    if (this.isInfinity) {
      this.$emit("update:high-limit", null);
    }
  }

  reset() {
    this.isInfinity = this.isLast && this.highLimit == null;
  }

  @Watch("isLast")
  onLengthChange = this.reset;

  @Watch("highLimit")
  onPropChange = this.triggerValidation;
}
